<template>
  <master-layout
    smallTitle="i tuoi"
    bigTitle="Ordini"
    :showInfoPopHover="true"
    :infoPopHover="
      whappyClientConfig.name == 'Yoube'
        ? INFOPOPOVER_MAP[whappyClientConfig.name]
        : INFOPOPOVER_MAP['default']
    "
  >
    <div class="page ion-padding-top">
      <!-- <button @click="getData" class="button-primary p-d">carica ordini</button> -->
      <div v-if="loadingGetData" class="font-bold">Caricamento in corso...</div>
      <div class="font-bold" v-else-if="listaOrdini.length == 0">
        Non ci sono ordini
      </div>
      <div v-else>
        <div
          v-for="ordine in listaOrdini"
          :key="ordine.id"
          class="flex flex-column mb-4 card-collapsable-dark p-d border-radius-client shadow-3"
          @click="ordine.collapsed = !ordine.collapsed"
        >
          <div class="flex justify-content-end">
            <span class="text-10 mb-2 font-bold">{{
              new Date(ordine.dataOrdine).toLocaleDateString("it", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
            }}</span>
          </div>
          <div class="flex justify-content-between mb-2">
            <span class="font-bold">{{ ordine.client_name }}</span>
            <span>ID Ordine: {{ ordine.id }}</span>
          </div>

          <div class="flex justify-content-between">
            <span class="font-bold">{{
              ordine.confirmed ? "Confermato" : "Non confermato"
            }}</span>

            <span>{{
              Number(ordine.tot_price).toLocaleString("it", {
                style: "currency",
                currency: "EUR",
              })
            }}</span>
          </div>
          <div v-if="ordine.collapsed" class="flex flex-column pt-4">
            <div class="flex justify-content-between mb-2">
              <span class="font-bold">Prodotti </span>
            </div>
            <div
              v-for="item in ordine.items"
              :key="item.id"
              class="flex justify-content-between align-items-center"
            >
              <span>{{ item.nome }}</span>
              <span class="font-bold">{{
                Number(item.price).toLocaleString("it", {
                  style: "currency",
                  currency: "EUR",
                })
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </master-layout>
</template>
<script setup>
import { ref } from "vue"
import { useStore } from "vuex"
import ApiService from "../../common/service.api"

const store = useStore()

const INFOPOPOVER_MAP = {
  Yoube: "Lista ordini",
  default: "Lista ordini",
}

const listaOrdini = ref([])
const loadingGetData = ref(false)
function getData() {
  loadingGetData.value = true
  listaOrdini.value.splice(0)
  ApiService.get(
    `Ecommerce/GetOrdersBySeller/${store.getters["user/getUserData"].id}`
  )
    .then((res) => {
      listaOrdini.value = res.data
      listaOrdini.value.map((ordine) => {
        ordine.collapsed = false
      })
    })
    .finally(() => {
      loadingGetData.value = false
    })
}

getData()
</script>
